<template>
  <moe-page title="添加app版本">
    <moe-form ref="paramsForm" :model="params" :rules="rules">
      <el-form-item label="构建版本" prop="build">
        <el-input :value="params.build" @input="(value) => params.build = value.replace(/^\.+|[^\d]/g,'')" placeholder="请输入构建版本" clearable />
      </el-form-item>
      <el-form-item label="版本号" prop="version">
        <el-input v-model="params.version" placeholder="请输入版本号" maxlength="50" clearable/>
      </el-form-item>

      <el-form-item label="应用平台" prop="platform">
        <el-radio-group v-model="params.platform">
          <el-radio label="iOS">iOS</el-radio>
          <el-radio label="android">android</el-radio>
        </el-radio-group>
      </el-form-item>
      <template v-if="params.platform === 'android'">
        <el-form-item label="Android下载地址" prop="url">
          <moe-upload-file
            v-model="params.url"
            ossKey="APP_RELEASE"
            :default-file-list="urlList"
            :suffix="['apk']"
            :getAndroidApkMd5="true"
            :md5.sync="params.md5"
            :size="1000"
            @change="$refs.paramsForm.validateField('url')">
          </moe-upload-file>
        </el-form-item>
        <el-form-item label="Android安装包MD5值" prop="md5">
          <el-input v-model="params.md5" placeholder="请输入Android安装包MD5值" clearable/>
        </el-form-item>
      </template>

      <el-form-item label="是否展示" prop="status">
        <el-radio-group v-model="params.status">
          <el-radio :label="1">展示</el-radio>
          <el-radio :label="2">隐藏</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="英文描述" prop="msgEnUs">
        <el-input type="textarea" autosize v-model="params.msgEnUs" placeholder="请输入英文描述" clearable/>
      </el-form-item>
      <el-form-item label="简体中文描述" prop="msgZhCn">
        <el-input type="textarea" autosize v-model="params.msgZhCn" placeholder="请输入简体中文描述" clearable/>
      </el-form-item>
      <el-form-item label="繁体中文描述" prop="msgZhHk">
        <el-input type="textarea" autosize v-model="params.msgZhHk" placeholder="请输入繁体中文描述" clearable/>
      </el-form-item>

      <el-form-item label="强制升级" prop="forced">
        <el-radio-group v-model="params.forced">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <template slot="tool">
        <el-button
          type="primary"
          icon="el-icon-upload2"
          :loading="paramsLoad"
          @click="submit">
          {{ paramsLoad ? '提交中' : '提交信息' }}
        </el-button>
      </template>
    </moe-form>
  </moe-page>
</template>

<script>
export default {
  name: 'AppVersionAdd',
  data() {
    let params = {
      build: '',       //构建版本
      md5: '',         //Android安装包MD5值
      msgEnUs: '',     //英文描述
      msgZhCn: '',     //简体中文描述
      msgZhHk: '',     //繁体中文描述
      status: 1,       //是否展示 1:是,2:否
      platform: 'iOS', //应用平台
      url: '',         //Android下载地址
      version: '',     //版本号
      forced: false,   //强制升级
    }
    //数据校验
    const verify = this.$moe_verify.verifyForm;
    return {
      params,
      urlList: [],
      rules: {
        build: verify.isEmpty('请输入构建版本'),
        version: verify.isEmpty('请输入版本号'),
        platform: verify.isEmpty('请选择应用平台'),
        url: verify.isEmpty('请上传Android下载地址'),
        md5: verify.isEmpty('请输入Android安装包MD5值'),
        status: verify.isEmpty('请选择是否展示'),
      },
      paramsLoad: false,
    }
  },
  methods: {
    submit() {
      this.$refs['paramsForm'].validate(() => {
        this.paramsLoad = true;
        this.$moe_api.VERSION_API.versionAdd(this.params).then((data) => {
          if (data.code == 200) {
            this.$moe_msg.success('添加成功')
            this.$router.go(-1);
          } else {
            this.$moe_msg.error(data.message)
          }
        }).finally(() => {
          this.paramsLoad = false
        })
      })
    }
  }
}
</script>